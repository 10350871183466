import {
  Speedometer,
  Bank,
  BusFront,
  Gear,
  PersonVideo2,
  Journal,
  UniversalAccessCircle,
  ChatRightDots,
  JournalAlbum,
  PcDisplayHorizontal,
  Stack,
  Fingerprint,
  PeopleFill,
  CardChecklist,
  FileBarGraph,
  CreditCardFill,
  MortarboardFill,
  PersonLinesFill,
  TruckFront,
  SignTurnRightFill,
  CashCoin,
  WalletFill,
  ListStars,
  Book,
  Coin,
  JournalCheck,
  Clipboard2Data,
  Building,
  Table,
  PersonBoundingBox,
  CardList,
  Calendar,
  GraphUpArrow,
  PlusSlashMinus,
  PersonBadge,
  ExclamationOctagon,
  PersonRolodex,
  PersonCheck,
  PersonAdd,
  People,
  CalendarX,
  Newspaper,
  ChatLeftDots,
  BuildingGear,
  Diagram3Fill,
  FileCodeFill,
  DeviceSsd,
  Receipt,
  Youtube,
} from "react-bootstrap-icons";
import { ROLE_ADMIN, ROLE_TEACHER } from "app/config/roles";
import { INSTITUTE_TYPES } from "app/config/institutes";
import {
  AutoGraph,
  AutoStories,
  Collections,
  ContactEmergency,
  DataUsage,
  EmojiEvents,
  Foundation,
  Groups3,
  HistoryEdu,
  ManageAccounts,
  PhotoCamera,
  PrecisionManufacturing,
  PriceChange,
  PsychologyAlt,
  School,
  Settings,
  ViewCarousel,
  WhatsApp,
} from "@mui/icons-material";
import appRoutes from "./routes";

export const sidebarItems = [
  {
    key: "dashboard",
    name: "Dashboard",
    icon: <Speedometer size={15} />,
    link: `/dashboard`,
    roles: [ROLE_ADMIN, ROLE_TEACHER],
    instituteTypes: [INSTITUTE_TYPES.SCHOOL],
    module: "DASHBOARD",
    screenName: "DASHBOARD",
  },
  {
    key: "admission-enquiry",
    name: "Admission Enquiry",
    icon: <ContactEmergency size={15} />,
    roles: [ROLE_ADMIN, ROLE_TEACHER],
    instituteTypes: [INSTITUTE_TYPES.SCHOOL],
    module: "ADMISSION_ENQUIRY",
    screenName: "ENQUIRY_FORM",
    subMenu: [
      {
        key: "visuals",
        name: "Visuals",
        icon: <AutoGraph size={15} />,
        link: `admission-enquiry/visuals`,
        roles: [ROLE_ADMIN, ROLE_TEACHER],
        instituteTypes: [INSTITUTE_TYPES.SCHOOL],
        module: "ADMISSION_ENQUIRY",
        screenName: "VISUALS",
      },

      {
        key: "enquiry",
        name: "Enquiry",
        icon: <PeopleFill size={15} />,
        link: `admission-enquiry/enquiry`,
        roles: [ROLE_ADMIN, ROLE_TEACHER],
        instituteTypes: [INSTITUTE_TYPES.SCHOOL],
        module: "ADMISSION_ENQUIRY",
        screenName: "ENQUIRY_FORM",
      },
      {
        key: "counselor",
        name: "Counselor",
        icon: <ManageAccounts size={15} />,
        link: `admission-enquiry/counselor`,
        roles: [ROLE_ADMIN, ROLE_TEACHER],
        instituteTypes: [INSTITUTE_TYPES.SCHOOL],
        module: "ADMISSION_ENQUIRY",
        screenName: "COUNSELOR",
      },
      {
        key: "formFeeStructure",
        name: "Form Fee Structure",
        icon: <PriceChange size={15} />,
        link: `admission-enquiry/formFeeStructure`,
        roles: [ROLE_ADMIN, ROLE_TEACHER],
        instituteTypes: [INSTITUTE_TYPES.SCHOOL],
        module: "ADMISSION_ENQUIRY",
        screenName: "ENQUIRY_FORM_FEE_STRUCTURE",
      },
      {
        key: "previousSchool",
        name: "Previous School",
        icon: <HistoryEdu size={15} />,
        link: `admission-enquiry/previousSchool`,
        roles: [ROLE_ADMIN, ROLE_TEACHER],
        instituteTypes: [INSTITUTE_TYPES.SCHOOL],
        module: "ADMISSION_ENQUIRY",
        screenName: "PREVIOUS_SCHOOL",
      },
    ],
  },
  {
    key: "student-management",
    name: "Student Management",
    icon: <Bank size={15} />,
    roles: [ROLE_ADMIN, ROLE_TEACHER],
    instituteTypes: [INSTITUTE_TYPES.SCHOOL],
    module: "STUDENT_MANAGEMENT",
    screenName: "STUDENT_MANAGEMENT",
    subMenu: [
      {
        key: "admissionApproval",
        name: "Admission Approval",
        icon: <CardChecklist size={15} />,
        link: `student-management/admission-approval`,
        roles: [ROLE_ADMIN, ROLE_TEACHER],
        instituteTypes: [INSTITUTE_TYPES.SCHOOL],
        module: "STUDENT_MANAGEMENT",
        screenName: "ADMISSION_APPROVAL",
      },

      {
        key: "students",
        name: "Students",
        icon: <PeopleFill size={15} />,
        link: `student-management/students`,
        roles: [ROLE_ADMIN, ROLE_TEACHER],
        instituteTypes: [INSTITUTE_TYPES.SCHOOL],
        module: "STUDENT_MANAGEMENT",
        screenName: "STUDENT",
      },
      {
        key: "student-report",
        name: "Student Report",
        icon: <FileBarGraph size={15} />,
        link: `student-management/student-report`,
        roles: [ROLE_ADMIN, ROLE_TEACHER],
        instituteTypes: [INSTITUTE_TYPES.SCHOOL],
        module: "STUDENT_MANAGEMENT",
        screenName: "STUDENT_REPORT",
      },
      {
        key: "bulkEdit",
        name: "Bulk Edit",
        icon: <FileBarGraph size={15} />,
        link: `student-management/bulkEdit`,
        roles: [ROLE_ADMIN, ROLE_TEACHER],
        instituteTypes: [INSTITUTE_TYPES.SCHOOL],
        module: "STUDENT_MANAGEMENT",
        screenName: "BULK_EDIT",
      },

      {
        key: "idCard",
        name: "Id Card",
        icon: <CreditCardFill size={15} />,
        link: `student-management/idCard`,
        roles: [ROLE_ADMIN, ROLE_TEACHER],
        instituteTypes: [INSTITUTE_TYPES.SCHOOL],
        module: "STUDENT_MANAGEMENT",
        screenName: "STUDENTS_ID_CARD",
      },
      {
        key: "promotionAndMigration",
        name: "Promotion And Migration",
        icon: <PersonLinesFill size={15} />,
        link: `student-management/promotionAndMigration`,
        roles: [ROLE_ADMIN, ROLE_TEACHER],
        instituteTypes: [INSTITUTE_TYPES.SCHOOL],
        module: "STUDENT_MANAGEMENT",
        screenName: "PROMOTION_MIGRATION",
      },
      {
        key: "alumni",
        name: "Transfer Certificate(TC)",
        icon: <MortarboardFill size={15} />,
        link: `student-management/alumni`,
        roles: [ROLE_ADMIN, ROLE_TEACHER],
        instituteTypes: [INSTITUTE_TYPES.SCHOOL],
        module: "STUDENT_MANAGEMENT",
        screenName: "ALUMNI",
      },
      {
        key: "certificate",
        name: "Misc. Certificate",
        icon: <MortarboardFill size={15} />,
        link: `student-management/certificate`,
        roles: [ROLE_ADMIN, ROLE_TEACHER],
        instituteTypes: [INSTITUTE_TYPES.SCHOOL],
        module: "STUDENT_MANAGEMENT",
        screenName: "CERTIFICATE",
      },
    ],
  },
  {
    key: "transportation",
    name: "Transportation",
    icon: <BusFront size={15} />,
    // link: `/transportation`,
    roles: [ROLE_ADMIN, ROLE_TEACHER],
    instituteTypes: [INSTITUTE_TYPES.SCHOOL],
    module: "TRANSPORT_MANAGEMENT",
    screenName: "TRANSPORT_MANAGEMENT",
    subMenu: [
      {
        key: "transporDetail",
        name: "Transport Detail",
        icon: <TruckFront size={15} />,
        link: `transportation/transport-detail`,
        roles: [ROLE_ADMIN, ROLE_TEACHER],
        instituteTypes: [INSTITUTE_TYPES.SCHOOL],
        module: "TRANSPORT_MANAGEMENT",
        screenName: "TRANSPORT_DETAIL",
      },
      {
        key: "routeDetail",
        name: "Route Detail",
        icon: <SignTurnRightFill size={15} />,
        link: `transportation/route-detail`,
        roles: [ROLE_ADMIN, ROLE_TEACHER],
        instituteTypes: [INSTITUTE_TYPES.SCHOOL],
        module: "TRANSPORT_MANAGEMENT",
        screenName: "TRANSPORT_ROUTE_DETAIL",
      },
      {
        key: "feeStructure",
        name: "Fee Structure",
        icon: <CashCoin size={15} />,
        link: `transportation/fee-structure`,
        roles: [ROLE_ADMIN, ROLE_TEACHER],
        instituteTypes: [INSTITUTE_TYPES.SCHOOL],
        module: "TRANSPORT_MANAGEMENT",
        screenName: "TRANSPORT_FEE_STRUCTURE",
      },
    ],
  },
  {
    key: "fees_management",
    name: "Fee Management",
    icon: <Journal size={15} />,
    roles: [ROLE_ADMIN, ROLE_TEACHER],
    instituteTypes: [INSTITUTE_TYPES.SCHOOL],
    module: "FEE_MANAGEMENT",
    screenName: "FEE_MANAGEMENT",
    subMenu: [
      {
        key: "fee",
        name: "Fee Structure",
        icon: <ListStars size={15} />,
        link: `fees_management/fees-structure`,
        roles: [ROLE_ADMIN, ROLE_TEACHER],
        instituteTypes: [INSTITUTE_TYPES.SCHOOL],
        module: "FEE_MANAGEMENT",
        screenName: "FEE_STRUCTURE",
      },
      {
        key: "collect-fee",
        name: "Collect Fee",
        icon: <WalletFill size={15} />,
        link: `fees_management/collect-fee`,
        roles: [ROLE_ADMIN, ROLE_TEACHER],
        instituteTypes: [INSTITUTE_TYPES.SCHOOL],
        module: "FEE_MANAGEMENT",
        screenName: "FEE_STRUCTURE",
      },
      {
        key: "fee_collection",
        name: "Old Fee Collection",
        icon: <WalletFill size={15} />,
        link: `fees_management/fees_collection`,
        roles: [ROLE_ADMIN, ROLE_TEACHER],
        instituteTypes: [INSTITUTE_TYPES.SCHOOL],
        module: "FEE_MANAGEMENT",
        screenName: "OLD_FEE_COLLECTION",
      },
      {
        key: "new-fee-collection",
        name: "Due List",
        icon: <Coin size={15} />,
        link: `fees_management/new-fee-collection`,
        roles: [ROLE_ADMIN, ROLE_TEACHER],
        instituteTypes: [INSTITUTE_TYPES.SCHOOL],
        module: "FEE_MANAGEMENT",
        screenName: "FEE_COLLECTION",
      },
      {
        key: "fee_report",
        name: "Fee Report",
        icon: <PersonLinesFill size={15} />,
        link: `fees_management/fees_report`,
        roles: [ROLE_ADMIN, ROLE_TEACHER],
        instituteTypes: [INSTITUTE_TYPES.SCHOOL],
        module: "FEE_MANAGEMENT",
        screenName: "FEE_REPORT",
      },
      {
        key: "fee_invoice",
        name: "Fee Invoice/Receipt",
        icon: <Receipt size={15} />,
        link: `fees_management/fees_invoice`,
        roles: [ROLE_ADMIN, ROLE_TEACHER],
        instituteTypes: [INSTITUTE_TYPES.SCHOOL],
        module: "FEE_MANAGEMENT",
        screenName: "FEE_REPORT",
      },
      {
        key: "fee_discount",
        name: "Discount",
        icon: <Journal size={15} />,
        link: `fees_management/fees_discount`,
        roles: [ROLE_ADMIN, ROLE_TEACHER],
        instituteTypes: [INSTITUTE_TYPES.SCHOOL],
        module: "FEE_MANAGEMENT",
        screenName: "FEE_REPORT",
      },
      // {
      //   key: "fee_report",
      //   name: "Fee Report",
      //   icon: <PersonLinesFill size={15} />,
      //   link: `fees_management/fees_report`,
      //   roles: [ROLE_ADMIN, ROLE_TEACHER],
      //   instituteTypes: [INSTITUTE_TYPES.SCHOOL],
      //   module: "FEE_MANAGEMENT",
      //   screenName: "FEE_REPORT",
      // },
      {
        key: "arrear",
        name: "Arrear",
        icon: <CashCoin size={15} />,
        link: `fees_management/arrear`,
        roles: [ROLE_ADMIN, ROLE_TEACHER],
        instituteTypes: [INSTITUTE_TYPES.SCHOOL],
        module: "FEE_MANAGEMENT",
        screenName: "FEE_ARREAR",
      },
    ],
  },
  {
    key: "ledger-n-account",
    name: "Ledger/Accounts",
    icon: <Journal size={15} />,
    roles: [ROLE_ADMIN, ROLE_TEACHER],
    instituteTypes: [INSTITUTE_TYPES.SCHOOL],
    module: "LEDGER_N_ACCOUNT",
    screenName: "LEDGER_N_ACCOUNT",
    subMenu: [
      {
        key: "books",
        name: "Books",
        icon: <Book size={15} />,
        link: `ledger-n-account/books`,
        roles: [ROLE_ADMIN, ROLE_TEACHER],
        instituteTypes: [INSTITUTE_TYPES.SCHOOL],
        module: "BOOKS",
        screenName: "BOOK",
      },
      {
        key: "collections",
        name: "Collections",
        icon: <JournalCheck size={15} />,
        link: `ledger-n-account/collections`,
        roles: [ROLE_ADMIN, ROLE_TEACHER],
        instituteTypes: [INSTITUTE_TYPES.SCHOOL],
        module: "LEDGER_N_ACCOUNT",
        screenName: "COLLECTION",
      },
      {
        key: "expenses",
        name: "Expenses",
        icon: <CashCoin size={15} />,
        link: `ledger-n-account/expenses`,
        roles: [ROLE_ADMIN, ROLE_TEACHER],
        instituteTypes: [INSTITUTE_TYPES.SCHOOL],
        module: "LEDGER_N_ACCOUNT",
        screenName: "EXPENSE",
      },
      {
        key: "ledgerReport",
        name: "Ledger Report",
        icon: <Clipboard2Data size={15} />,
        link: `ledger-n-account/ledger-report`,
        roles: [ROLE_ADMIN, ROLE_TEACHER],
        instituteTypes: [INSTITUTE_TYPES.SCHOOL],
        module: "LEDGER_N_ACCOUNT",
        screenName: "LEDGER_REPORT",
      },
      {
        key: "firms",
        name: "Firms",
        icon: <Building size={15} />,
        link: `ledger-n-account/firms`,
        roles: [ROLE_ADMIN, ROLE_TEACHER],
        instituteTypes: [INSTITUTE_TYPES.SCHOOL],
        module: "LEDGER_N_ACCOUNT",
        screenName: "EXPENSE",
      },
      {
        key: "firmInvoice",
        name: "Firm Invoice",
        icon: <Receipt size={15} />,
        link: `ledger-n-account/firm-invoice`,
        roles: [ROLE_ADMIN, ROLE_TEACHER],
        instituteTypes: [INSTITUTE_TYPES.SCHOOL],
        module: "LEDGER_N_ACCOUNT",
        screenName: "FIRM_INVOICE",
      },
    ],
  },
  {
    key: "academics",
    name: "Academics",
    icon: <Stack size={15} />,
    roles: [ROLE_ADMIN, ROLE_TEACHER],
    instituteTypes: [INSTITUTE_TYPES.SCHOOL],
    module: "ACADEMIC",
    subMenu: [
      {
        key: "timetable",
        name: "Time Table",
        icon: <Table size={15} />,
        link: `academics/timetable`,
        roles: [ROLE_ADMIN, ROLE_TEACHER],
        instituteTypes: [INSTITUTE_TYPES.SCHOOL],
        module: "ACADEMIC",
        screenName: "TIME_TABLE",
      },
      {
        key: "attendanceAndLeave",
        name: "Attendance & Leave",
        icon: <PersonBoundingBox size={15} />,
        link: `academics/attendance-and-leave`,
        roles: [ROLE_ADMIN, ROLE_TEACHER],
        instituteTypes: [INSTITUTE_TYPES.SCHOOL],
        module: "ACADEMIC",
        screenName: "ATTENDANCE_N_LEAVE",
      },
      {
        key: "homework",
        name: "Homework",
        icon: <JournalCheck size={15} />,
        link: `academics/homework`,
        roles: [ROLE_ADMIN, ROLE_TEACHER],
        instituteTypes: [INSTITUTE_TYPES.SCHOOL],
        module: "ACADEMIC",
        screenName: "HOMEWORK",
      },
      {
        key: "syllabus",
        name: "Syllabus",
        icon: <CardList size={15} />,
        link: `academics/syllabus`,
        roles: [ROLE_ADMIN, ROLE_TEACHER],
        instituteTypes: [INSTITUTE_TYPES.SCHOOL],
        module: "ACADEMIC",
        screenName: "HOMEWORK",
      },
      {
        key: "academicCalendar",
        name: "Academic Calendar",
        icon: <Calendar size={15} />,
        link: `academics/academic-calendar`,
        roles: [ROLE_ADMIN, ROLE_TEACHER],
        instituteTypes: [INSTITUTE_TYPES.SCHOOL],
        module: "ACADEMIC_CALENDER",
        screenName: "EVENTS",
      },
    ],
  },

  {
    key: "exam-control",
    name: "Exam Control",
    icon: <JournalAlbum size={15} />,
    roles: [ROLE_ADMIN, ROLE_TEACHER],
    instituteTypes: [INSTITUTE_TYPES.SCHOOL],
    module: "EXAM_CONTROL",
    screenName: "EXAM_CONTROL",
    subMenu: [
      {
        key: "testAndExams",
        name: "Tests & Exams",
        icon: <PlusSlashMinus size={15} />,
        link: `exam-control/tests-and-exams`,
        roles: [ROLE_ADMIN, ROLE_TEACHER],
        instituteTypes: [INSTITUTE_TYPES.SCHOOL],
        module: "EXAM_CONTROL",
        screenName: "TEST_N_EXAM",
      },
      {
        key: "admitCard",
        name: "Admit Card",
        icon: <PersonBadge size={15} />,
        link: `exam-control/admit-card`,
        roles: [ROLE_ADMIN, ROLE_TEACHER],
        instituteTypes: [INSTITUTE_TYPES.SCHOOL],
        module: "EXAM_CONTROL",
        screenName: "ADMIT_CARD",
      },
      {
        key: "reportCard",
        name: "Report Card",
        icon: <GraphUpArrow size={15} />,
        link: `exam-control/report-card`,
        roles: [ROLE_ADMIN, ROLE_TEACHER],
        instituteTypes: [INSTITUTE_TYPES.SCHOOL],
        module: "EXAM_CONTROL",
        screenName: "REPORT_CARD",
      },
    ],
  },

  {
    key: "reception",
    name: "Reception",
    icon: <PcDisplayHorizontal size={15} />,
    roles: [ROLE_ADMIN, ROLE_TEACHER],
    instituteTypes: [INSTITUTE_TYPES.SCHOOL],
    module: "RECEPTION",
    screenName: "RECEPTION",
    subMenu: [
      {
        key: "admissionEnquiry",
        name: "Admission Enquiry",
        icon: <PersonCheck size={15} />,
        link: `reception/admission-enquiry`,
        roles: [ROLE_ADMIN, ROLE_TEACHER],
        instituteTypes: [INSTITUTE_TYPES.SCHOOL],
        module: "RECEPTION",
        screenName: "ADMISSION_ENQUIRY",
      },
      {
        key: "complaints",
        name: "Complaints",
        icon: <ExclamationOctagon size={15} />,
        link: `reception/complaints`,
        roles: [ROLE_ADMIN, ROLE_TEACHER],
        instituteTypes: [INSTITUTE_TYPES.SCHOOL],
        module: "RECEPTION",
        screenName: "COMPLAINT",
      },
      {
        key: "staffAttendance",
        name: "Staff Attendance",
        icon: <PersonRolodex size={15} />,
        link: `reception/staff-attendance`,
        roles: [ROLE_ADMIN, ROLE_TEACHER],
        instituteTypes: [INSTITUTE_TYPES.SCHOOL],
        module: "RECEPTION",
        screenName: "STAFF_ATTENDANCE",
      },
      {
        key: "visitorControl",
        name: "Visitor Control",
        icon: <PersonCheck size={15} />,
        link: `reception/visitor-control`,
        roles: [ROLE_ADMIN, ROLE_TEACHER],
        instituteTypes: [INSTITUTE_TYPES.SCHOOL],
        module: "RECEPTION",
        screenName: "VISITOR_CONTROL",
      },
    ],
  },

  {
    key: "hr",
    name: "Human Resources",
    icon: <PersonVideo2 size={15} />,
    roles: [ROLE_ADMIN, ROLE_TEACHER],
    instituteTypes: [INSTITUTE_TYPES.SCHOOL],
    module: "HUMAN_RESOURCE",
    screenName: "HUMAN_RESOURCE",
    subMenu: [
      {
        key: "joiningApproval",
        name: "Joining Approval",
        icon: <PersonAdd size={15} />,
        link: `hr/joining-approval`,
        roles: [ROLE_ADMIN, ROLE_TEACHER],
        instituteTypes: [INSTITUTE_TYPES.SCHOOL],
        module: "HUMAN_RESOURCE",
        screenName: "JOINING_APPROVAL",
      },
      {
        key: "staffManagement",
        name: "Staff Management",
        icon: <People size={15} />,
        link: `hr/staff-management`,
        roles: [ROLE_ADMIN, ROLE_TEACHER],
        instituteTypes: [INSTITUTE_TYPES.SCHOOL],
        module: "HUMAN_RESOURCE",
        screenName: "STAFF_MANAGEMENT",
      },
      {
        key: "staffLeave",
        name: "Staff Leave",
        icon: <CalendarX size={15} />,
        link: `hr/staff-leave`,
        roles: [ROLE_ADMIN, ROLE_TEACHER],
        instituteTypes: [INSTITUTE_TYPES.SCHOOL],
        module: "HUMAN_RESOURCE",
        screenName: "STAFF_LEAVE",
      },
      {
        key: "payroll",
        name: "Payroll",
        icon: <WalletFill size={15} />,
        link: `hr/payroll`,
        roles: [ROLE_ADMIN, ROLE_TEACHER],
        instituteTypes: [INSTITUTE_TYPES.SCHOOL],
        module: "HUMAN_RESOURCE",
        screenName: "PAYROLL",
      },
    ],
  },

  {
    key: "communication",
    name: "Communication",
    icon: <ChatRightDots size={15} />,
    roles: [ROLE_ADMIN, ROLE_TEACHER],
    instituteTypes: [INSTITUTE_TYPES.SCHOOL],
    module: "COMMUNICATION",
    screenName: "COMMUNICATION",
    subMenu: [
      {
        key: "noticeBoard",
        name: "Notice Board",
        icon: <Newspaper size={15} />,
        link: `communication/notice-board`,
        roles: [ROLE_ADMIN, ROLE_TEACHER],
        instituteTypes: [INSTITUTE_TYPES.SCHOOL],
        module: "COMMUNICATION",
        screenName: "NOTICE_BOARD",
      },
      {
        key: "textSMS",
        name: "Text SMS",
        icon: <ChatLeftDots size={15} />,
        link: `communication/text-sms`,
        roles: [ROLE_ADMIN, ROLE_TEACHER],
        instituteTypes: [INSTITUTE_TYPES.SCHOOL],
        module: "COMMUNICATION",
        screenName: "TEXT_SMS",
      },
      {
        key: "whatsApp",
        name: "WhatsApp Message",
        icon: <WhatsApp size={15} />,
        link: `communication/whatsapp`,
        roles: [ROLE_ADMIN, ROLE_TEACHER],
        instituteTypes: [INSTITUTE_TYPES.SCHOOL],
        module: "COMMUNICATION",
        screenName: "WHATSAPP",
      },
    ],
  },

  {
    key: "management",
    name: "School Management",
    icon: <Gear size={15} />,
    roles: [ROLE_ADMIN, ROLE_TEACHER],
    instituteTypes: [INSTITUTE_TYPES.SCHOOL],
    module: "SCHOOL_MANAGEMENT",
    screenName: "SCHOOL_MANAGEMENT",
    subMenu: [
      {
        key: "sessionSetting",
        name: "Session Setting",
        icon: <BuildingGear size={15} />,
        link: `management/session-settings`,
        roles: [ROLE_ADMIN, ROLE_TEACHER],
        instituteTypes: [INSTITUTE_TYPES.SCHOOL],
        module: "SCHOOL_MANAGEMENT",
        screenName: "SESSION_SETTING",
      },
      {
        key: "billsUsage",
        name: "Bills & Usage",
        icon: <DataUsage size={15} />,
        link: `management/bills-usage`,
        roles: [ROLE_ADMIN],
        instituteTypes: [INSTITUTE_TYPES.SCHOOL],
        module: "SCHOOL_MANAGEMENT",
        screenName: "BILLS_USAGE",
      },
      {
        key: "classes",
        name: "Classes",
        icon: <Diagram3Fill size={15} />,
        link: `management/classes`,
        roles: [ROLE_ADMIN, ROLE_TEACHER],
        instituteTypes: [INSTITUTE_TYPES.SCHOOL],
        module: "SCHOOL_MANAGEMENT",
        screenName: "CLASS",
      },
      {
        key: "departments",
        name: "Departments",
        icon: <Building size={15} />,
        link: `management/departments`,
        roles: [ROLE_ADMIN, ROLE_TEACHER],
        instituteTypes: [INSTITUTE_TYPES.SCHOOL],
        module: "SCHOOL_MANAGEMENT",
        screenName: "DEPARTMENT",
      },
      {
        key: "invitationCode",
        name: "Invitation Code",
        icon: <FileCodeFill size={15} />,
        link: `management/invitation-code`,
        roles: [ROLE_ADMIN, ROLE_TEACHER],
        instituteTypes: [INSTITUTE_TYPES.SCHOOL],
        module: "SCHOOL_MANAGEMENT",
        screenName: "INVITATION_CODE",
      },
      {
        key: "accessControl",
        name: "Access Control",
        icon: <UniversalAccessCircle size={15} />,
        link: `management/access-control`,
        roles: [ROLE_ADMIN, ROLE_TEACHER],
        instituteTypes: [INSTITUTE_TYPES.SCHOOL],
        module: "ACCESS_CONTROL",
        screenName: "PERMISSION",
      },
    ],
  },
  {
    key: "bio-metric",
    name: "Bio-Metric",
    icon: <Fingerprint size={15} />,
    roles: [ROLE_ADMIN, ROLE_TEACHER],
    instituteTypes: [INSTITUTE_TYPES.SCHOOL],
    module: "BIO_METRIC",
    screenName: "BIO_METRIC",
    subMenu: [
      {
        key: "device-detail",
        name: "Device Detail",
        icon: <DeviceSsd size={15} />,
        link: `bio-metric/device-detail`,
        roles: [ROLE_ADMIN, ROLE_TEACHER],
        instituteTypes: [INSTITUTE_TYPES.SCHOOL],
        module: "BIO_METRIC",
        screenName: "DEVICE_DETAIL",
      },
      {
        key: "punch-log",
        name: "Punch Log",
        icon: <PersonCheck size={15} />,
        link: `bio-metric/punch-log`,
        roles: [ROLE_ADMIN, ROLE_TEACHER],
        instituteTypes: [INSTITUTE_TYPES.SCHOOL],
        module: "BIO_METRIC",
        screenName: "PUNCH_LOG",
      },
      {
        key: "user-detail",
        name: "User Detail",
        icon: <PeopleFill size={15} />,
        link: `bio-metric/user-detail`,
        roles: [ROLE_ADMIN, ROLE_TEACHER],
        instituteTypes: [INSTITUTE_TYPES.SCHOOL],
        module: "BIO_METRIC",
        screenName: "USER_DETAIL",
      },
    ],
  },
  {
    key: "website-builder",
    name: "Website Builder",
    icon: <PrecisionManufacturing size={15} />,
    roles: [ROLE_ADMIN, ROLE_TEACHER],
    instituteTypes: [INSTITUTE_TYPES.SCHOOL],
    module: "WEBSITE_BUILDER",
    screenName: "SCHOOL_GALLERY",
    subMenu: [
      {
        key: "website-slider",
        name: "Website Slider",
        icon: <ViewCarousel size={15} />,
        link: `website-builder/website-slider`,
        roles: [ROLE_ADMIN, ROLE_TEACHER],
        instituteTypes: [INSTITUTE_TYPES.SCHOOL],
        module: "WEBSITE_BUILDER",
        screenName: "SLIDER",
      },
      {
        key: "website-popup",
        name: "Website Popup",
        icon: <AutoStories size={15} />,
        link: `website-builder/website-popup`,
        roles: [ROLE_ADMIN, ROLE_TEACHER],
        instituteTypes: [INSTITUTE_TYPES.SCHOOL],
        module: "WEBSITE_BUILDER",
        screenName: "POP_UP",
      },
      {
        key: "thought-of-the-day",
        name: "Thought of the Day",
        icon: <PsychologyAlt size={15} />,
        link: `website-builder/thought-of-the-day`,
        roles: [ROLE_ADMIN, ROLE_TEACHER],
        instituteTypes: [INSTITUTE_TYPES.SCHOOL],
        module: "WEBSITE_BUILDER",
        // screenName: "THOUGHT",
      },
      {
        key: "school-galleryr",
        name: "School Gallery",
        icon: <Collections size={15} />,
        link: `website-builder/school-gallery`,
        roles: [ROLE_ADMIN, ROLE_TEACHER],
        instituteTypes: [INSTITUTE_TYPES.SCHOOL],
        module: "WEBSITE_BUILDER",
        screenName: "SCHOOL_GALLERY",
      },

      {
        key: "class-photo",
        name: "Class Photo",
        icon: <PhotoCamera size={15} />,
        link: `website-builder/class-photo`,
        roles: [ROLE_ADMIN, ROLE_TEACHER],
        instituteTypes: [INSTITUTE_TYPES.SCHOOL],
        module: "WEBSITE_BUILDER",
        screenName: "CLASS_PHOTOS",
      },
      {
        key: "achievement",
        name: "Achievement",
        icon: <EmojiEvents size={15} />,
        link: `website-builder/achievement`,
        roles: [ROLE_ADMIN, ROLE_TEACHER],
        instituteTypes: [INSTITUTE_TYPES.SCHOOL],
        module: "WEBSITE_BUILDER",
        screenName: "ACHIEVEMENT",
      },
      {
        key: "school-topper",
        name: "School Topper",
        icon: <School size={15} />,
        link: `website-builder/school-topper`,
        roles: [ROLE_ADMIN, ROLE_TEACHER],
        instituteTypes: [INSTITUTE_TYPES.SCHOOL],
        module: "WEBSITE_BUILDER",
        screenName: "SCHOOL_TOPPER",
      },
      {
        key: "founder",
        name: "Founder",
        icon: <Foundation size={15} />,
        link: `website-builder/founder`,
        roles: [ROLE_ADMIN, ROLE_TEACHER],
        instituteTypes: [INSTITUTE_TYPES.SCHOOL],
        module: "WEBSITE_BUILDER",
        screenName: "FOUNDER",
      },
      {
        key: "school-team",
        name: "School Team",
        icon: <Groups3 size={15} />,
        link: `website-builder/school-team`,
        roles: [ROLE_ADMIN, ROLE_TEACHER],
        instituteTypes: [INSTITUTE_TYPES.SCHOOL],
        module: "WEBSITE_BUILDER",
        screenName: "SCHOOL_TEAM",
      },
      {
        key: "youtube-videos",
        name: "YouTube Videos",
        icon: <Youtube size={15} />,
        link: `${appRoutes?.youtubeVideos}`,
        roles: [ROLE_ADMIN, ROLE_TEACHER],
        instituteTypes: [INSTITUTE_TYPES.SCHOOL],
        module: "WEBSITE_BUILDER",
        screenName: "YOUTUBE_VIDEO",
      },
      {
        key: "news-calendar",
        name: "News Calendar",
        icon: <Newspaper size={15} />,
        link: `${appRoutes?.newsCalendar}`,
        roles: [ROLE_ADMIN, ROLE_TEACHER],
        instituteTypes: [INSTITUTE_TYPES.SCHOOL],
        module: "WEBSITE_BUILDER",
        screenName: "NEWS_CALENDAR",
      },
      {
        key: "about-school-website",
        name: "About School",
        icon: <Newspaper size={15} />,
        link: `${appRoutes?.aboutSchoolWebsite}`,
        roles: [ROLE_ADMIN, ROLE_TEACHER],
        instituteTypes: [INSTITUTE_TYPES.SCHOOL],
        module: "WEBSITE_BUILDER",
        screenName: "ABOUT_SCHOOL_WEBSITE",
      },
      // {
      //   key: "sport-achiever",
      //   name: "Sport Achiever",
      //   icon: <SportsHandball size={15} />,
      //   link: `website-builder/sport-achiever`,
      //   roles: [ROLE_ADMIN, ROLE_TEACHER],
      //   instituteTypes: [INSTITUTE_TYPES.SCHOOL],
      //   module: "BIO_METRIC",
      //   screenName: "SPORT_ACHIEVER",
      // },
      // {
      //   key: "chief-guest",
      //   name: "Chief Guest",
      //   icon: <Person3 size={15} />,
      //   link: `website-builder/chief-guest`,
      //   roles: [ROLE_ADMIN, ROLE_TEACHER],
      //   instituteTypes: [INSTITUTE_TYPES.SCHOOL],
      //   module: "BIO_METRIC",
      //   screenName: "CHIEF_GUEST",
      // },
      // {
      //   key: "alumni-achiever",
      //   name: "Alumni Achiever",
      //   icon: <Sports size={15} />,
      //   link: `website-builder/alumni-achiever`,
      //   roles: [ROLE_ADMIN, ROLE_TEACHER],
      //   instituteTypes: [INSTITUTE_TYPES.SCHOOL],
      //   module: "BIO_METRIC",
      //   screenName: "ALUMNI_ACHIEVER",
      // },
    ],
  },
  {
    key: "payment-gateway",
    name: "Payment Gateway",
    icon: <Collections size={15} />,
    roles: [ROLE_ADMIN, ROLE_TEACHER],
    instituteTypes: [INSTITUTE_TYPES.SCHOOL],
    module: "PAYMENT_GATEWAY",
    screenName: "PAYEMNT_GATEWAY",
    subMenu: [
      {
        key: "orders",
        name: "ORDERS",
        icon: <Collections size={15} />,
        link: `${appRoutes?.orders}`,
        roles: [ROLE_ADMIN, ROLE_TEACHER],
        instituteTypes: [INSTITUTE_TYPES.SCHOOL],
        module: "PAYMENT_GATEWAY",
        screenName: "PAYMENT_GATEWAY_ORDER",
      },
      {
        key: "configuration",
        name: "Configuration",
        icon: <Settings size={15} />,
        link: `${appRoutes?.configuration}`,
        roles: [ROLE_ADMIN, ROLE_TEACHER],
        instituteTypes: [INSTITUTE_TYPES.SCHOOL],
        module: "PAYMENT_GATEWAY",
        screenName: "PAYMENT_GATEWAY_CONFIG",
      },
    ],
  },

  // {
  // key: "otherUtility",
  // name: "Other Utility",
  // icon: <ShieldLock size={15} />,
  // link: `/other-utility`,
  // roles: [ROLE_ADMIN, ROLE_TEACHER],
  // instituteTypes: [INSTITUTE_TYPES.SCHOOL],
  // module: "OTHER_UTILITY",
  // screenName: "OTHER_UTILITY",
  // },
];
